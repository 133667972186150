import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Transition, TransitionStatus } from "react-transition-group";
import styled from "styled-components";
import { useState, useEffect } from "react";

import { Theme, theme1 } from "../Theme";

interface ProjectPopupProps {
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  fullscreen?: boolean;
}

type transitions = {
  [entering in TransitionStatus]: React.CSSProperties;
};

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const trans: transitions = {
  entering: {
    top: "100%"
  },
  entered: {
    top: "0%"
  },
  exiting: {
    top: "100%"
  },
  exited: {
    top: "100%"
  },
  unmounted: {
    top: "0",
  },
};

const transFullscreen: transitions = {
  ...trans,
  entered: {
    ...trans.entered,
    top: "0",
    width: "100%",
    left: "0",
  },
  exiting: {
    ...trans.exiting,
  },
};

export const ProjectPopUp = (props: ProjectPopupProps) => {
  const [transitionState, setTransitionState] = useState(false);
  const handleClose = async (target: any, currentTarget: any) => {
    if (target === currentTarget) {
      setTransitionState(false);
      await timeout(300);
      props.setOpen(false);
    }
  };

  useEffect(() => {
    setTransitionState(true);
  }, []);

  return (
    <Container
      onClick={(event) => {
        handleClose(event.target, event.currentTarget);
      }}
    >
      <Transition in={transitionState} timeout={300}>
        {(state) => {
          const stateStyling = props.fullscreen
            ? transFullscreen[state]
            : trans[state];
          return (
            <Content
              fullscreen={props.fullscreen ? true : false}
              style={{ transition: "all .4s ease", ...stateStyling, ...theme1 }}
            >
              {props.fullscreen === true && (
                <CloseDialogeButton
                  onClick={(event) => handleClose(event.target, event.target)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </CloseDialogeButton>
              )}
              {props.children}
            </Content>
          );
        }}
      </Transition>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;

  :hover {
    cursor: pointer;
  }
`;

const Content = styled.div<{ fullscreen: boolean; style: Theme }>`
  position: relative;
  border-radius: ${(props) => (props.fullscreen ? "0px" : "15px")};
  ${(props) => (props.fullscreen ? "height: 100%" : "max-height: 95vh")};
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  background-color: ${(props) => props.style.Background};
  max-width: 1500px;
  width: 80%;
  margin: ${(props) => (props.fullscreen ? "0 auto" : "2rem auto")};

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  :hover {
    cursor: default;
  }

  hr {
    width: 90%;
    border: 0;
    background-color: ${(props) => props.style.PrimaryColor};
    height: 1px;
    margin: 0 auto;
  }
`;

const CloseDialogeButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  color: rgba(100, 100, 128, 0.4);
  width: 32px;
  height: 32px;
  padding: 20px;
  z-index: 500;

  :hover {
    color: rgba(100, 100, 100, 1);
    cursor: pointer;
  }

  svg {
    height: inherit;
    width: inherit;
  }
`;
