import {
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { theme1, Theme } from "./Theme";

export const Links = () => {
  return (
    <Container style={theme1}>
      <h1>Kieran Coppins, BSc (Hons)</h1>
      <h2>Graduate Software Engineer</h2>
      <h3>Lincolnshire, United Kingdom</h3>
      <SocialsContainer style={theme1}>
        <Icon
          icon={faLinkedin}
          text="Kieran Coppins"
          link="https://www.linkedin.com/in/kieranmdcoppins/"
        />
        <Icon
          icon={faGithub}
          text="KieranCoppins"
          link="https://github.com/KieranCoppins"
        />
        <Icon icon={faCode} text="Portfolio" link="/" />
        <Icon
          icon={faTwitter}
          text="@KCoppins_"
          link="https://twitter.com/kcoppins_"
        />
        <Icon
          icon={faInstagram}
          text="@KCoppins"
          link="https://www.instagram.com/kierancoppins/"
        />
      </SocialsContainer>
    </Container>
  );
};

const Icon = ({
  icon,
  text,
  link,
}: {
  icon: IconDefinition;
  text: string;
  link: string;
}) => (
  <IconContainer style={theme1}>
    <a href={link} target={"_blank"} rel="noreferrer">
      <FontAwesomeIcon size="3x" icon={icon} />
      <span id="tooltip">
        <p>{text}</p>
      </span>
    </a>
  </IconContainer>
);

const IconContainer = styled.span<{ style: Theme }>`
  margin: 1rem;
  a {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 72px;
    height: 72px;
    cursor: pointer;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 0;
    visibility: hidden;
    transition: 0.5s ease;
    background-color: ${(props) => props.style.PrimaryColor};
    color: white;
    border-radius: 15px;
    overflow: hidden;
    height: auto;
    bottom: 0;
    opacity: 0;
  }

  span p {
    margin: 5px 10px;
    white-space: nowrap;
  }

  :hover {
    span {
      bottom: -30px;
      max-width: 200px;
      opacity: 1;
      visibility: visible;
    }
  }

  svg {
    color: ${(props) => props.style.PrimaryColor};
    height: 65px;
    width: 65px;
  }

  @media screen and (max-width: 1024px) {
    a {
      height: 44px;
      width: 44px;
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
`;

const Container = styled.div<{ style: Theme }>`
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.style.Background};
  color: ${(props) => props.style.Header};

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 80px;
  }
  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 60px;
  }
  h3 {
    margin: 0;
    font-weight: 300;
    font-size: 50px;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 25px;
    }
  }
`;

const SocialsContainer = styled.div<{ style: Theme }>`
  display: flex;
  color: ${(props) => props.style.PrimaryColor};
`;
