export interface Theme {
  Background: string;
  Header: string;
  Body: string;
  PrimaryColor: string;
  TertuaryColor: string;
  ButtonHover: string;
  Border: string;
}

export const theme1: Theme = {
  Background: "#F4FAFF",
  Header: "#08090A",
  Body: "#08090A",
  PrimaryColor: "#AE847E",
  TertuaryColor: "#71B48D",
  ButtonHover: "#ae847ec7",
  Border: "#AE847E",
};
