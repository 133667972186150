import styled from "styled-components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faGithub,
  faYoutube,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";

import { faFile } from "@fortawesome/free-regular-svg-icons";

import { ProjectPopUp } from "./ProjectPopup";
import useWindowDimensions from "../Hooks/WindowDimensions";

import { Theme, theme1 } from "../Theme";

interface ProjectComponentProps {
  ProjectName: string;
  ProjectSubText?: string;
  CoverImages: string[];
  GitHubRepo?: string;
  VideoLink?: string;
  FileLink?: string;
  size: "lg" | "sm";
  ProjectTechnologies: string[];
  Content: JSX.Element;
}

const ProjectIcon = (
  link: string,
  icon: IconDefinition,
  id: string,
  download: boolean
) => {
  return (
    <a
      href={link}
      target={"_blank"}
      rel="noreferrer"
      id={id}
      className="icon"
      download={download ? link : null}
    >
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

const ProjectComponent = (props: ProjectComponentProps) => {
  const [openPopup, setOpenPopUp] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const [fullscreen, setFullscreen] = useState<boolean>(width <= 1200);
  useEffect(() => {
    setFullscreen(width <= 1200);
  }, [width]);
  return (
    <>
      {openPopup && (
        <ProjectPopUp setOpen={setOpenPopUp} fullscreen={fullscreen}>
          <ProjectImagesContainer>
            <Carousel slides={props.CoverImages.length}>
              {props.CoverImages.map((image) => (
                <img src={image} alt={props.ProjectName + "_Cover"} />
              ))}
            </Carousel>
          </ProjectImagesContainer>
          <ProjectHeaderWrapper style={theme1}>
            <h2>{props.ProjectName}</h2>
            <ProjectIcons style={theme1}>
              {props.GitHubRepo &&
                ProjectIcon(props.GitHubRepo, faGithub, "GitHub", false)}
              {props.VideoLink &&
                ProjectIcon(props.VideoLink, faYoutube, "YouTube", false)}
              {props.FileLink &&
                ProjectIcon(props.FileLink, faFile, "PDFFile", true)}
            </ProjectIcons>
          </ProjectHeaderWrapper>
          <hr />
          <ProjectTextContent>{props.Content}</ProjectTextContent>
        </ProjectPopUp>
      )}

      <Container size={props.size} style={theme1}>
        <img
          src={props.CoverImages[0]}
          alt={props.ProjectName + "_Cover"}
          onClick={() => setOpenPopUp(true)}
        />
        <InformationContainer
          className={"overlay"}
          size={props.size}
          style={theme1}
        >
          <TextContainer style={theme1} size={props.size}>
            <p className={"projectTitle"}>{props.ProjectName}</p>
            {props.ProjectSubText && (
              <p className={"projectSub"}>{props.ProjectSubText}</p>
            )}
            <TechnologiesContainer>
              {props.ProjectTechnologies.map((tech) => (
                <StyledTag style={theme1}>
                  <p>{tech}</p>
                </StyledTag>
              ))}
            </TechnologiesContainer>
          </TextContainer>
          {props.GitHubRepo && (
            <a
              href={props.GitHubRepo}
              target={"_blank"}
              rel="noreferrer"
              id="GitHub"
              className="icon"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )}
        </InformationContainer>
      </Container>
    </>
  );
};

const Container = styled.div<{ size: string; style: Theme }>`
  width: ${(props) => (props.size === "lg" ? "384px" : "320px")};
  height: ${(props) => (props.size === "lg" ? "384px" : "320px")};
  position: relative;
  display: flex;
  justify-content: center;
  transition: 0.3s ease;
  box-shadow: 2px 2px 10px rgba(1, 1, 1, 0.4);

  background-color: ${(props) => props.style.Background};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    max-height: 0;
    height: auto;
  }

  :hover {
    box-shadow: 4px 4px 10px rgba(1, 1, 1, 0.7);
    transform: translateY(-6px);
    img {
      cursor: pointer;
    }
    .overlay {
      max-height: 100%;
    }
  }
`;

const TextContainer = styled.div<{ size: string; style: Theme }>`
  margin: auto;
  margin-left: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  min-width: 0;

  .projectTitle {
    font-size: ${(props) => (props.size === "lg" ? "27px" : "22px")};
    font-weight: 500;
    color: ${(props) => props.style.Header};
  }

  .projectSub {
    font-size: ${(props) => (props.size === "lg" ? "18px" : "15px")};
    font-weight: 300;
    margin-left: 10px;
    color: ${(props) => props.style.Body};
  }
`;

const InformationContainer = styled.div<{ size: string; style: Theme }>`
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: ${(props) => props.style.Background};
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  transition: 1s ease;
  justify-content: center;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.4);
  width: 100%;

  p {
    margin: 0;
  }

  a {
    margin: auto;
    width: ${(props) => (props.size === "lg" ? "48px" : "40px")};
    height: ${(props) => (props.size === "lg" ? "48px" : "40px")};
    margin-right: ${(props) => (props.size === "lg" ? "15px" : "12px")};
    padding: ${(props) => (props.size === "lg" ? "12px" : "10px")};
  }

  .icon {
    color: ${(props) => props.style.PrimaryColor};
  }

  .icon svg {
    width: inherit;
    height: inherit;
  }

  #GitHub :hover {
    color: ${(props) => props.style.TertuaryColor};
  }
`;

const ProjectImagesContainer = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 33%;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  gap: 20px;
`;

const Carousel = styled.div<{ slides: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: ${(props) => (100 * props.slides).toString() + "%"};

  img {
    scroll-snap-align: center;
    object-fit: cover;
    width: ${(props) => (100 / props.slides).toString() + "%"};
  }
`;

const ProjectHeaderWrapper = styled.div<{ style: Theme }>`
  padding: 20px 10%;
  padding-bottom: 0;
  display: flex;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-20px);
  background-color: inherit;

  h2 {
    margin: auto;
    font-weight: 500;
    font-size: 27px;
    color: ${(props) => props.style.Header};
    flex-grow: 4;
  }
`;

const ProjectIcons = styled.div<{ style: Theme }>`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;

  .icon {
    width: 32px;
    height: 32px;
    color: ${(props) => props.style.PrimaryColor};
    padding: 10px;
  }

  .icon svg {
    width: inherit;
    height: inherit;
  }

  .icon:hover {
    color: ${(props) => props.style.TertuaryColor};
  }
`;

const ProjectTextContent = styled.div`
  margin: 20px 15%;
  text-align: justify;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul li {
    position: relative;
    padding: 0 0 0 5px;
    margin: 0 20px;
    p {
      margin: 0;
    }
  }

  ul li::before {
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: -12px;
    left: -10px;
  }

  div > ul > li:first-child::before {
    top: 12px;
  }

  ul li:not(:first-child):last-child:before {
    display: none;
  }

  ul li:only-child::before {
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: 7px;
    height: 7px;
    left: -10px;
  }

  ul li::after {
    content: " ";
    position: absolute;
    left: -10px;
    width: 10px;
    height: 1px;
    background-color: #000;
    top: 12px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }

  p {
    font-weight: 300;
    margin-left: 20px;
  }

  div {
    margin-left: 40px;
  }

  b {
    font-weight: 500;
  }

  i {
    font-weight: 400;
  }
`;

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 5px;
  width: 100%;
  max-height: 60px;
  margin-left: 20px;
`;

const StyledTag = styled.span<{ style: Theme }>`
  padding: 4px 8px;
  border-radius: 18px;
  min-width: auto;
  background-color: ${(props) => props.style.TertuaryColor};
  color: white;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 6px;

  p {
    white-space: nowrap;
    font-size: 12px;
  }
`;

export default ProjectComponent;
