import React from "react";
import { Routes, Route } from "react-router-dom";
import { Portfolio } from "./Portfolio";
import { Links } from "./Links";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Portfolio />} />
        <Route path="/links" element={<Links />} />
      </Routes>
    </>
  );
}

export default App;
